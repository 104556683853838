import {primary} from "../dps/primaryDps.js";
import {addArrowDown, addArrowUp, addCommas, addInfo, colorCells, sortRows} from "../utils.js";

const rows = primary.split('\n');
const headers = rows[0].split('\t').slice(1);

let currentSortColumn = null;
let currentSortOrder = null;
let maxValues = {
  "Body": Number.NEGATIVE_INFINITY,
  "Head": Number.NEGATIVE_INFINITY,
  "Burst": Number.NEGATIVE_INFINITY
};

let weapons = [];
function Weapon(name, bodyDps, headDps, burstDps, backgroundColor, borderColor, info) {
  this.name = name;
  this.bodyDps = bodyDps;
  this.headDps = headDps;
  this.burstDps = burstDps;
  this.backgroundColor = backgroundColor;
  this.borderColor = borderColor;
  this.info = info;
}

const addRow = (rowData) => {
  const row = document.createElement('tr');
  let weapon = new Weapon("\t" + rowData[1], rowData[2], rowData[3], rowData[4]);
  for (let i = 1; i <= headers.length; i++) {
    const cell = document.createElement('td');
    if (i > 1 && i < 5) {
      cell.innerHTML = addCommas(rowData[i]);
    } else {
      cell.innerHTML = rowData[i];
    }
    if (i === 1) {
      const splitArray = rowData[0].split(':');
      const type = splitArray[0];
      const info = splitArray[1];
      if (info !== undefined) {
        addInfo(cell, info);
        weapon.info = info;
      }
      if (type === 'Legendary') {
        cell.style.backgroundColor = `rgb(211, 183, 243)`;
        weapon.backgroundColor = "rgba(116,48,155,0.2)";
        weapon.borderColor = "rgb(116,48,155)";
      } else {
        cell.style.backgroundColor = `rgb(236, 232, 191)`;
        weapon.backgroundColor = "rgba(211,189,64, 0.2)";
        weapon.borderColor = "rgb(211,189,64)";
      }
    } else {
      const numericValue = parseFloat(rowData[i]);
      if (!isNaN(numericValue)) {
        maxValues[headers[i - 1]] = Math.max(maxValues[headers[i - 1]], numericValue);
      }
    }
    row.appendChild(cell);
  }
  weapons.push(weapon);
  document.querySelector('#primary-body').appendChild(row);
}

for (let i = 1; i < rows.length; i++) {
  addRow(rows[i].split('\t'));
}

const headersArray = [...document.querySelectorAll('#primary-table th')];
for (const header of headersArray) {
  header.style.cursor = 'pointer';
  header.addEventListener('click', (event) => {
    for (const header of headersArray) header.style.backgroundColor = null;
    event.target.style.backgroundColor = '#ddd';
    const headerIndex = headersArray.indexOf(event.target);
    if (headerIndex === currentSortColumn) {
      if (currentSortOrder === 'asc') {
        currentSortOrder = 'desc';
        event.target.appendChild(addArrowDown(headersArray, currentSortColumn));
      } else {
        currentSortOrder = 'asc';
        event.target.appendChild(addArrowUp(headersArray, currentSortColumn));
      }
    } else {
      currentSortColumn = headerIndex;
      currentSortOrder = 'desc';
      event.target.appendChild(addArrowDown(headersArray, currentSortColumn));
    }
    sortRows(currentSortColumn, 1, currentSortOrder, '#primary-body');
    sortGraph();
  });
}

colorCells('#primary-body', headers, maxValues);

function sortGraph() {
  let dpsData = {};

  const sortFunctions = [
    (a, b) => currentSortOrder === 'asc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name),
    (a, b) => currentSortOrder === 'desc' ? b.bodyDps - a.bodyDps : a.bodyDps - b.bodyDps,
    (a, b) => currentSortOrder === 'desc' ? b.headDps - a.headDps : a.headDps - b.headDps,
    (a, b) => currentSortOrder === 'desc' ? b.burstDps - a.burstDps : a.burstDps - b.burstDps,
  ];

  const sortColumnData = [
    {label: 'Head DPS', key: 'headDps'},
    {label: 'Body DPS', key: 'bodyDps'},
    {label: 'Head DPS', key: 'headDps'},
    {label: 'Burst DPS', key: 'burstDps'},
  ];

  weapons.sort(sortFunctions[currentSortColumn]);

  const {label, key} = sortColumnData[currentSortColumn];
  dpsData.label = label;
  dpsData.data = weapons.map(weapon => weapon[key]);
  dpsData.backgroundColor = weapons.map(weapon => weapon.backgroundColor);
  dpsData.borderColor = weapons.map(weapon => weapon.borderColor);
  dpsData.borderWidth = 1;

  graph.data.datasets = [dpsData];
  graph.data.labels = weapons.map(weapon => weapon.name);

  graph.update();
}

let dpsData = {
  label: "Head DPS",
  data: weapons.map(function(weapon) {
    return weapon.headDps;
  }),
  backgroundColor: weapons.map(function(weapon) {
    return weapon.backgroundColor;
  }),
  borderColor: weapons.map(function(weapon) {
    return weapon.borderColor;
  }),
  borderWidth: 1,
};

const ctx = document.getElementById("primary-graph").getContext("2d");
const graph = new Chart(ctx, {
  type: "horizontalBar",
  data: {
    labels: weapons.map(function(weapon) {
      return weapon.name;
    }),
    datasets: [dpsData],
  },
  options: {
    responsive: true,
    aspectRatio: 1 / (rows.length / 25),
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      callbacks: {
        title: function(tooltipItems) {
          const weapon = weapons[tooltipItems[0].index];
          return weapon.info;
        },
        label: function(tooltipItems, data) {
          return `\t${data.datasets[0].label}: ${addCommas(tooltipItems.value)}`;
        }
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: "Damage Per Second",
        }
      }],
      yAxes: [{
        barPercentage: 1,
        categoryPercentage: 0.9,
        ticks: {
          mirror: true
        },
        scaleLabel: {
          display: true,
          labelString: "Weapon",
        }
      }]
    }
  }
});
