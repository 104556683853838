export const primary = `Type\tFrame\tBody\tHead\tBurst
Legendary\tAdaptive Auto Rifle\t3102\t4837\t6196
Legendary\tHigh-Impact Auto Rifle\t2919\t4659\t5616
Legendary\tPrecision Auto Rifle\t3019\t4705\t5920
Legendary\tRapid-Fire Auto Rifle\t3492\t5223\t6340
Exotic\tCentrifuse\t3053\t4757\t5906
Exotic:Focused Fire\tCerberus+1\t8467\t10210\t11435
Exotic\tHardlight\t3240\t5052\t6178
Exotic\tMonte Carlo\t3074\t4792\t9151
Exotic\tNecrochasm\t3326\t4975\t6377
Exotic\tQuicksilver Storm\t12323\t13066\t35592
Exotic:Spinning Up\tSuros Regime\t3433\t5061\t7102
Exotic\tSweet Business\t6153\t8284\t9868
Exotic:Ember of Ashes\tTommy's Matchbook\t6628\t9607\t12087
Legendary\tLightweight Combat Bow\t2660\t4250\t4888
Legendary\tPrecision Combat Bow\t2783\t4175\t5093
Exotic:40+ Meters\tHierarchy of Needs\t13214\t15104\t15406
Exotic\tLe Monarque\t6470\t7970\t9166
Exotic:Causality Arrows x5\tTicuu's Divination\t6151\t10843\t14377
Exotic\tTrinity Ghoul\t2886\t4327\t5192
Exotic\tVerglas Curve\t2965\t4738\t4928
Exotic\tWish-Ender\t7889\t9194\t13515
Exotic\tWish-Keeper\t3078\t4386\t4824
Exotic\tVex Mythoclast\t6456\t10316\t21429
Exotic\tFighting Lion\t7015\t7015\t12276
Legendary\tAdaptive Hand Cannon\t2481\t3836\t5386
Legendary\tAggressive Hand Cannon\t2294\t3667\t5279
Legendary\tHeavy Burst Hand Cannon\t2399\t4306\t6161
Legendary:Lucky Pants\tWarden's Law\t6609\t16712\t39068
Legendary\tPrecision Hand Cannon\t2721\t4082\t5781
Exotic\tAce of Spades\t2573\t3977\t9053
Exotic\tCrimson\t2763\t4416\t6715
Exotic\tHawkmoon\t2459\t11552\t38908
Exotic\tLumina\t2524\t4268\t5833
Exotic\tMalfeasance\t8948\t10418\t13172
Exotic:Lucky Pants\tMalfeasance\t14031\t18505\t37076
Exotic\tSturm\t2424\t3875\t5093
Exotic:Sturm Overcharge\tSturm\t10569\t16891\t18426
Exotic\tSunshot\t2732\t4165\t5869
Exotic\tThe Last Word\t2638\t5660\t10805
Exotic\tThorn\t3978\t5267\t7923
Legendary\tAdaptive Pulse Rifle\t3089\t4961\t5882
Legendary\tAggressive Pulse Rifle\t2757\t4654\t5588
Legendary\tHigh-Impact Pulse Rifle\t2968\t4744\t5785
Legendary\tLightweight Pulse Rifle\t2887\t4648\t5823
Legendary\tRapid-Fire Pulse Rifle\t3002\t4977\t6122
Exotic\tBad Juju\t2730\t4385\t5991
Exotic\tCollective Obligation\t3040\t4884\t5931
Exotic\tGraviton Lance\t4375\t7244\t8815
Exotic\tNo Time to Explain\t2828\t6173\t6716
Exotic:5 Nanites\tOutbreak Perfected\t7302\t13201\t16523
Exotic:100 Nanites\tOutbreak Perfected\t16063\t27274\t34138
Exotic:\tRevision Zero\t2913\t13423\t29013
Exotic\tVigilance Wing\t4222\t6169\t7295
Legendary\tAggressive Scout Rifle\t2349\t4583\t6551
Legendary\tHigh-Impact Scout Rifle\t2537\t4452\t5598
Legendary\tLightweight Scout Rifle\t2604\t4568\t5837
Legendary\tPrecision Scout Rifle\t2884\t4604\t5658
Legendary\tRapid-Fire Scout Rifle\t2921\t4955\t6303
Exotic:Cranial Spike x5\tDead Man's Tale\t3692\t7846\t10779
Exotic\tMIDA Multi-Tool\t2604\t4568\t5811
Exotic:Ember of Ashes\tPolaris Lance\t2506\t7589\t8131
Exotic:Ember of Ashes\tSkyburner's Oath\t5493\t5493\t6433
Exotic\tSymmetry\t7078\t7835\t10987
Exotic\tThe Jade Rabbit\t2501\t4783\t5466
Exotic\tTouch of Malice\t8528\t17694\t23005
Exotic\tWicked Implement\t2884\t9193\t11378
Legendary\tAdaptive Sidearm\t5128\t7183\t9279
Legendary\tAdaptive Burst Sidearm\t4627\t7394\t9744
Legendary\tAggressive Burst Sidearm\t4992\t6987\t8422
Legendary\tLightweight Sidearm\t4791\t6718\t9745
Legendary\tPrecision Sidearm\t4781\t6706\t8961
Legendary\tRapid-Fire Sidearm\t4806\t6741\t9775
Exotic\tCryosthesia 77K\t4612\t6462\t9070
Exotic:\tDevil's Ruin\t7505\t10515\t16781
Exotic:\tFinal Warning\t7524\t16175\t19256
Exotic\tRat King\t4802\t6727\t9418
Exotic:Rat Pack x5\tRat King\t7926\t11104\t15744
Exotic\tTraveler's Chosen\t4938\t6918\t9340
Exotic\tTrespasser\t4617\t7378\t14322
Legendary\tAdaptive Submachine Gun\t3848\t5758\t8954
Legendary\tAggressive Submachine Gun\t3747\t5626\t8997
Legendary\tLightweight Submachine Gun\t3767\t6245\t9532
Legendary\tPrecision Submachine Gun\t4075\t5717\t8512
Exotic\tOsteo Striga\t8085\t9431\t12156
Exotic:Poison Only\tOsteo Striga\t5188\t5188\t5188
Exotic:Arc Conductor\tRiskrunner\t6404\t9582\t9773
Exotic:Ravenous Beast\tTarrabah\t13997\t14143\t21764
Exotic\tThe Huckleberry\t4163\t6251\t9771
Exotic:Swooping Talons\tThe Manticore\t5374\t8902\t13336`;
